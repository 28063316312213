import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import logo from "./logo.png";

const App = () => {
  const [apis, setApis] = useState([]);
  const [filter, setFilter] = useState('all');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Hardcoded credentials (in a real application, this should be handled securely)
  const validUsername = 'admin';
  const validPassword = 'Maida@123';

  const authenticate = () => {
    if (username === validUsername && password === validPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Invalid credentials');
    }
  };

  const fetchApis = useCallback(async () => {
    if (!isAuthenticated) return;
    try {
      const response = await axios.get('https://apps.maida.co:3000/get-apis');
      setApis(response.data);
    } catch (error) {
      console.error('Error fetching APIs:', error);
    }
  }, [isAuthenticated]);

  const checkApiStatus = useCallback(async () => {
    if (!isAuthenticated) return;
    try {
      const promises = apis.map(api => axios.get(`https://apps.maida.co:3000/check-api?name=${api.name}`));
      const responses = await Promise.all(promises);
      const updatedApis = responses.map((response, index) => ({ 
        ...apis[index], 
        status: response.data.status,
        url: response.data.url || apis[index].url // Use the URL from the response if available
      }));
      setApis(updatedApis);
    } catch (error) {
      console.error('Error checking API status:', error);
    }
  }, [apis, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchApis();
    }
  }, [fetchApis, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && apis.length > 0) {
      const interval = setInterval(() => {
        checkApiStatus();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [apis, checkApiStatus, isAuthenticated]);

  const filteredApis = apis.filter(api => {
    if (filter === 'all') return true;
    if (filter === 'online') return api.status === 'Active';
    if (filter === 'offline') return api.status !== 'Active';
    return true;
  });

  const redirectToApi = (api) => {
    if (api.url) {
      window.open(api.url, '_blank');
    } else {
      console.error(`No URL available for API: ${api.name}`);
      alert('API URL not available');
    }
  };

  if (!isAuthenticated) {
    return (
      <div style={{
        fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
        maxWidth: '400px',
        margin: '100px auto',
        padding: '40px',
        backgroundColor: '#f5f5f5',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }}>
        <h2 style={{textAlign: 'center', marginBottom: '20px'}}>Login</h2>
        <input 
          type="text" 
          placeholder="Username" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)}
          style={{width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ddd'}}
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)}
          style={{width: '100%', padding: '10px', marginBottom: '20px', borderRadius: '5px', border: '1px solid #ddd'}}
        />
        <button 
          onClick={authenticate}
          style={{width: '100%', padding: '10px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer'}}
        >
          Login
        </button>
      </div>
    );
  }

  return (
    <div style={{
      fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
      maxWidth: '800px',
      margin: '0 auto',
      padding: '40px 20px',
      backgroundColor: '#f5f5f5',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    }}>
      <img src={logo} alt="Logo" style={{ 
        width: '150px', 
        height: '150px', 
        display: 'block', 
        margin: '0 auto 30px',
      }} />
      <h1 style={{ 
        textAlign: 'center', 
        marginBottom: '30px',
        color: '#333',
        fontSize: '28px',
        fontWeight: '600'
      }}>MAIDA.CO APIs SERVER STATUS</h1>
      
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        marginBottom: '30px',
        backgroundColor: '#fff',
        padding: '10px',
        borderRadius: '25px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
      }}>
        {['all', 'online', 'offline'].map((option) => (
          <button
            key={option}
            onClick={() => setFilter(option)}
            style={{
              margin: '0 5px',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '20px',
              background: filter === option ? '#007bff' : 'transparent',
              color: filter === option ? 'white' : '#333',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              fontWeight: '500',
              outline: 'none'
            }}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>

      <div style={{
        backgroundColor: '#fff',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
      }}>
        {filteredApis.map((api, index) => (
          <div key={index} style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            padding: '20px', 
            borderBottom: index < filteredApis.length - 1 ? '1px solid #eee' : 'none',
            transition: 'background-color 0.3s ease'
          }}>
            <span style={{ fontSize: '18px', color: '#333', fontWeight: '500' }}>{api.name}</span>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ 
                padding: '8px 16px', 
                borderRadius: '20px', 
                background: api.status === 'Active' ? '#28a745' : '#dc3545',
                color: 'white',
                fontSize: '14px',
                fontWeight: '600',
                textTransform: 'uppercase',
                letterSpacing: '0.5px',
                marginRight: '10px'
              }}>
                {api.status === 'Active' ? 'Active' : 'Inactive'}
              </span>
              <button
                onClick={() => redirectToApi(api)}
                style={{
                  padding: '8px',
                  borderRadius: '50%',
                  background: '#007bff',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '36px',
                  height: '36px'
                }}
                title="Go to API"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;